import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { Calculator, CalculatorAside } from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import SectionTop from '../../components/SectionTop';
import staticData from '../index-data.json';
import './index.scss';

const WriteMyEssayForMe = () => (
  <Layout className="IndexPage WriteMyEssayForMe">
    <Helmet>
      <title>Write My Essay: Experts Who Know How to Handle Tricky Assignments | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Want to pay someone to write essay for you? Our service offers high-quality assistance with college writing assignments. Place your order with us and get the help you need in no time."
      />
      <link rel="canonical" href="https://ca.customwritings.com/write-my-essay-for-me.html" />
      <link
        rel="alternate"
        hrefLang="en"
        href="https://www.customwritings.com/write-my-essay.html"
      />
      <link rel="alternate" hrefLang="en-ca" href="https://ca.customwritings.com/write-my-essay-for-me.html" />
      <link rel="alternate" hrefLang="x-default" href="https://www.customwritings.com/write-my-essay.html" />
    </Helmet>

    <SectionTop>
      <h1 className="Section-top__content__title">Your “write my essay” request is answered by our top experts</h1>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">Order any assignment and get high-quality writing in keeping with the requirements of Canadian colleges</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Buy an essay</a>
    </SectionTop>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Visit us and say, “Write my essay for me”</h2>
      <p>
        If you’re studying in high school or college, you should already know that the amount of work an average
        student is supposed to complete every week is quite unrealistic. Sometimes it feels as if every individual
        teacher and professor believes that you have nothing to deal with in your life except his/her subject. Essays
        and other writing assignments, individual and group projects, lab reports, case studies, extracurricular
        activities, part-time jobs—you have to juggle a multitude of things at the same time, and can’t afford to
        neglect any of them. It’s only natural to look for a reliable academic assistance service and pay someone to
        write essay for you, since, at the very least, it can free up enough time to pay real attention to the tasks
        that truly matter. Put plainly, it’s the best way to write an essay if you don’t have time to pay enough
        attention to the task in question.
      </p>
      <p>
        However, if you’re studying in Canada, you can’t just pick any service and say, “Write my essay, please.”
        While writing conventions in Canada, the United States, and other English-speaking countries are very similar and sometimes identical, some differences still exist. As a result, it’s particularly obvious when somebody breaks them by mistakenly using another country’s formatting style or failing to follow the rules of the national variety of English. Even a very experienced specialist might write an essay that fails to meet the requirements of your college if you’re not careful about choosing a service that’s used to dealing with Canadian students—like, for example, CustomWritings.com.
      </p>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-2">Can you write my essay for free?</h2>
      <p>
        Many students looking for academic assistance keep asking one question: “Can you write my essay for free?”
        While the idea of receiving academic help without having to pay for it may sound attractive, it just isn’t
        really worth it. When you say, “Write essay for me free of charge,” you can’t expect any experienced
        specialist to do so. Even if you manage to find somebody who allegedly writes without payment, you’ll most
        likely receive a pre-written piece of text that has little to do with your assignment, and using something
        like this isn’t a very good idea. Therefore, instead of going around and saying, “Write my essay for free,”
        you’d do better focus on finding a trustworthy agency that will prepare a high-quality paper for a reasonable
        price. If you’re considering asking real writers, “Write my essay for me free of charge,” you can simply
        download a free paper from any publicly available source and have the same results.
      </p>
      <h2 className="Section__heading Section__heading--bubble-3">If you can’t write essay for me free of charge, what can you do?</h2>
      <p>
        If you visit our writing agency and say, “Write my essay free of charge,” we will be forced to reject your
        request. To write an essay, one has to apply a lot of time and effort, and we respect the work of our
        employees. However, if you simply say, “Write my essay for me,” we’ll be more than ready to discuss the
        details and offer you our assistance. There are many reasons to choose our agency over other companies working
        in this industry. Among them are:
      </p>
      <ul>
        <li>
          Our speed. When you contact our customer support and say, “Write essay for me,” we immediately jump into
          action. We go through our database of writers and find the one best suited to write essay paper on this
          particular topic. He/she immediately starts working on the paper, and even if the deadline is very close,
          nine times out of ten we finish the job on time.
        </li>
        <li>
          Our stance on plagiarism. When a client comes to us and says, “Write an essay for me,” we always produce a
          completely original piece of writing from scratch. Our writers never compile their work from bits and pieces
          of previous papers, nor do we resell the complete assignments we’ve already written. We check every paper
          for plagiarism using top-notch software to be extra sure.
        </li>
        <li>
          Our prices. We know that the bulk of our clients are students who can’t afford to spend much. When you visit
          us and say, “Write my essay online,” you can expect it to be reasonably priced. Our fees start at $10 per
          page and only get noticeably higher for urgent tasks. If you ask us to write essay online early enough, you
          can get your paper cheap even if it’s very complex.
        </li>
      </ul>
    </Section>

    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">I need you to write my essay online. What else do I have to know?</h2>
      <p>
        People that come to us and say, “I want you to write me an essay” usually want to know more about the service
        they are dealing with before they finalize their orders. If you’re such a person, you’ll be interested to know
        about the guarantees we offer our clients:
      </p>
      <ul>
        <li>
          When you come to us and say, “Write my college essay,” we promise that your paper will meet all your
          requirements. If you find any deviations from them, you can ask for any number of free revisions for 7 days
          after receiving it.
        </li>
        <li>
          You don’t have to worry about your money when you pay us to write essay papers. If something goes wrong, you
          can get a full or partial refund depending on the situation.
        </li>
        <li>
          We can write a paper following any regional standard. Simply say, “Write my essay, Canada style formatting
          required,” and we will do the rest.
        </li>
      </ul>
      <p>
        Hiring CustomWritings.com is an extremely easy way to get an essay done—try it out and see for yourself!
      </p>
    </Section>
  </Layout>
);

export default WriteMyEssayForMe;
